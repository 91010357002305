import axios from 'axios';
import ROUTES from '../api/routes';
import { TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';

export default function deleteItemTask(id) {
  const { accessToken } = JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME)) || {};

  return axios.delete(
    `${ROUTES.GET_TASKS}/${id}`,
    {
      headers: {
        accept: 'application/json',
        Authorization: accessToken,
        'content-type': 'application/json',
      },
    },
  )
    .catch((err) => err);
}
