import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import styled, { css } from 'styled-components/macro';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CB } from '../../components/FormComponents/index.jsx';
import { COLORS, FONTS, TRANSITIONS } from '../../../styles/Theme';
import FacilityInfoBlock from '../../components/FacilityInfoBlock/FacilityInfoBlock.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import DatePickerContainer from '../../components/DatePickerContainer/index.jsx';
import useOutsideClick from '../../../utils/useOutsideClick';
import Typography from '../../components/Typography';
import SVG from '../../components/SVG';
import SavingBarLineChartBlock from '../../components/SavingBarLineChartBlock/SavingBarLineChartBlock.jsx';
import SavingsTable from '../../components/SavingsTable/SavingsTable.jsx';

import getSavingsPageData from '../../../services/getSavingsPageData';
import getSavingsAvailablePeriod from '../../../services/getSavingsAvailablePeriod';
import formatToStringDot from '../../../utils/formatToStringDot';
import formatToStringGap from '../../../utils/formatToStringGap';
import { less } from '../../../styles';
import getSavingsPageReport from '../../../services/getSavingsPageReport';
import { DOWNLOAD_DATA_SAVINGS } from '../../../constants/ui.js';

const BASELINE_YEAR_RADIO = 'baselineYear';
const PREVIOUS_YEAR_RADIO = 'previousYear';

const tableColumns = [
  {
    Header: 'Electric',
    value: 'date',
    accessor: 'col1',
  },
  {
    Header: 'T-o',
    value: 't0',
    accessor: 'col2',
  },
  {
    Header: 'T-1',
    value: 't1',
    accessor: 'col3',
  },
  {
    Header: 'CPU-0',
    value: 'cpu0',
    accessor: 'col4',
  },
  {
    Header: 'CPU-1',
    value: 'cpu1',
    accessor: 'col5',
  },
  {
    Header: 'Baseline Cost (Adj)',
    value: 'comparedCost',
    accessor: 'col6',
  },
  {
    Header: '+ Weather Effect',
    value: 'weatherEffect',
    accessor: 'col7',
  },
  {
    Header: '+ Rates Effect',
    value: 'ratesEffect',
    accessor: 'col8',
  },
  {
    Header: '- This Year Bill',
    value: 'thisYearBill',
    accessor: 'col9',
  },
  {
    Header: 'Usage Savings',
    value: 'conservationEffect',
    accessor: 'col10',
  },
];

const tableColumnsOverall = [
  {
    Header: 'Electric',
    value: 'date',
    accessor: 'col1',
  },
  {
    Header: 'Baseline Cost (Adj)',
    value: 'comparedCost',
    accessor: 'col6',
  },
  {
    Header: '+ Weather Effect',
    value: 'weatherEffect',
    accessor: 'col7',
  },
  {
    Header: '+ Rates Effect',
    value: 'ratesEffect',
    accessor: 'col8',
  },
  {
    Header: '- This Year Bill',
    value: 'thisYearBill',
    accessor: 'col9',
  },
  {
    Header: 'Usage Savings',
    value: 'conservationEffect',
    accessor: 'col10',
  },
];

const SavingsPage = ({ isVisible }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);

  // DATEPICKER
  const [selectedStartDateFrom, setSelectedStartDateFrom] = useState(null);
  const [isVisiblePickerFrom, setVisiblePickerFrom] = useState(false);
  const [selectedStartDateTo, setSelectedStartDateTo] = useState(null);
  const [isVisiblePickerTo, setVisiblePickerTo] = useState(false);
  const [radioValue, setRadioValue] = useState(BASELINE_YEAR_RADIO);
  const [availablePeriod, setAvailablePeriod] = useState([]);

  const [charts, setCharts] = useState([]);
  const [tables, setTables] = useState([]);

  const [isVisibleExport, setIsVisibleExport] = useState(false);

  const datePickerRefFrom = useRef(null);
  const datePickerRefTo = useRef(null);
  const isMonthChangeRef = useRef(false);
  const exportDropdown = useRef(null);

  useEffect(() => {
    if (organizationId) {
      dispatch(getSavingsAvailablePeriod())
        .then((data = []) => {
          if (!isEmpty(data)) {
            const correctData = data.map((i) => {
              const date = new Date(i);
              date.setDate(1);
              return date;
            });
            setAvailablePeriod(correctData);

            const last = data[data.length - 1];

            const minYear = new Date(last);
            minYear.setFullYear(minYear.getFullYear() - 1);
            const minYearISO = minYear.toISOString();

            const previousIndex = data.findIndex((i) => i === minYearISO);
            const lastIndex = data.findIndex((i) => i === last);

            if (previousIndex > 0) {
              const dateFrom = new Date(data[previousIndex]);
              dateFrom.setDate(1);

              const dateLast = new Date(last);
              dateLast.setDate(1);

              setSelectedStartDateFrom(dateFrom);
              setSelectedStartDateTo(dateLast);
            } else {
              const dateFrom = new Date(data[lastIndex - 1]);
              dateFrom.setDate(1);

              const dateLast = new Date(last);
              dateLast.setDate(1);

              setSelectedStartDateFrom(dateFrom);
              setSelectedStartDateTo(dateLast);
            }
          }
        });
    }
  }, [organizationId]);

  useEffect(() => {
    if (selectedStartDateFrom && selectedStartDateTo && organizationId) {
      dispatch(getSavingsPageData(radioValue, selectedStartDateFrom, selectedStartDateTo))
        .then((data) => {
          if (!isEmpty(data)) {
            const charts = data?.Totals.map((i) => ({
              ...i,
              chartData: [
                i.comparedCost,
                i.weatherEffect,
                i.ratesEffect,
                i.conservationEffect,
                i.thisYearBill,
              ],
            }));

            let dataTables = [];

            for (let key in data) {
              if (data.hasOwnProperty(key)) {
                dataTables = [...dataTables, { name: key, data: data[key] }];
              }
            }

            const tables = dataTables
              .sort((a, b) => (a.data.length < b.data.length ? 1 : -1));

            setCharts(charts);
            setTables(tables);
          } else {
            setCharts([]);
            setTables([]);
          }
        });
    }
  }, [radioValue, selectedStartDateFrom, selectedStartDateTo, organizationId]);

  const handleStartDateSelectFrom = (date) => {
    setSelectedStartDateFrom(date);

    if (Date.parse(date) > Date.parse(selectedStartDateTo)) {
      setSelectedStartDateTo('');
    }
  };

  const handleStartDateSelectTo = (date) => {
    if (date) {
      setSelectedStartDateTo(date);
    }
  };

  const handleArrowClickFrom = (value) => () => {
    const newDate = new Date(selectedStartDateFrom);
    newDate.setMonth(newDate.getMonth() + value);

    const toDate = new Date(selectedStartDateTo);
    toDate.setMonth(toDate.getMonth() + 1);
    toDate.setDate(0);

    const formatAvailablePeriod = availablePeriod.map((i) => format(i, 'yyyy-MM-dd'));
    const formatNewDate = format(newDate, 'yyyy-MM-dd');

    const isAvailableDate = formatAvailablePeriod.find((i) => i === formatNewDate);

    if (isAvailableDate && newDate.getTime() < toDate.getTime()) {
      setSelectedStartDateFrom(newDate);

      if (Date.parse(newDate) > Date.parse(selectedStartDateTo)) {
        setSelectedStartDateTo('');
      }
    }
  };

  const handleArrowClickTo = (value) => () => {
    if (!selectedStartDateTo) return;

    const newDate = new Date(selectedStartDateTo);
    newDate.setMonth(newDate.getMonth() + value);

    const formatAvailablePeriod = availablePeriod.map((i) => format(i, 'yyyy-MM-dd'));
    const formatNewDate = format(newDate, 'yyyy-MM-dd');
    const formartDateFrom = format(new Date(selectedStartDateFrom), 'yyyy-MM-dd');
    const dateFromIndex = formatAvailablePeriod.indexOf(formartDateFrom);

    const availableDateArr = formatAvailablePeriod.splice(dateFromIndex);
    const isAvailableDate = availableDateArr.find((i) => i === formatNewDate);
    isAvailableDate && setSelectedStartDateTo(newDate);
  };

  useOutsideClick(datePickerRefFrom, () => {
    if (isVisiblePickerFrom && !isMonthChangeRef.current) handleSetVisiblePickerFrom();
  });

  useOutsideClick(datePickerRefTo, () => {
    if (isVisiblePickerTo && !isMonthChangeRef.current) handleSetVisiblePickerTo();
  });

  useOutsideClick(exportDropdown, () => {
    if (isVisibleExport) handleChangeVisabilityExport();
  });

  const handleSetVisiblePickerFrom = (e) => {
    e && e.stopPropagation();
    setVisiblePickerFrom(!isVisiblePickerFrom);
    setVisiblePickerTo(false);
    setIsVisibleExport(false);
  };

  const handleSetVisiblePickerTo = (e) => {
    e && e.stopPropagation();
    setVisiblePickerTo(!isVisiblePickerTo);
    setVisiblePickerFrom(false);
    setIsVisibleExport(false);
  };

  const handleChangeVisabilityExport = (e) => {
    e && e.stopPropagation();
    setIsVisibleExport(!isVisibleExport);
    setVisiblePickerTo(false);
    setVisiblePickerFrom(false);
  };

  const handleChangeYear = () => {
    isMonthChangeRef.current = true;

    setTimeout(() => {
      isMonthChangeRef.current = false;
    });
  };

  const handleCheck = (value) => () => {
    setRadioValue(value);
  };

  const handleDownloadClick = (value) => () => {
    dispatch(getSavingsPageReport(radioValue, selectedStartDateFrom, selectedStartDateTo, value));
  };

  const getTotals = (data = [], key) => {
    let total = 0;
    data.forEach((item) => {
      total += item[key];
    });
    return total;
  };

  const addTotalItem = (data) => ([
    ...data,
    {
      col1: 'Total',
      col2: getTotals(data, 'col2'),
      col3: getTotals(data, 'col3'),
      col4: getTotals(data, 'col4'),
      col5: getTotals(data, 'col5'),
      col6: getTotals(data, 'col6'),
      col7: getTotals(data, 'col7'),
      col8: getTotals(data, 'col8'),
      col9: getTotals(data, 'col9'),
      col10: getTotals(data, 'col10'),
    },
  ]);

  const setHeaderInTable = useCallback((data, title) => !isEmpty(data)
    && data.map((i, index) => (!index
      ? ({ ...i, Header: title })
      : i)), [tableColumns, tableColumnsOverall]);

  const mapItem = ({
    date,
    t0,
    t1,
    cpu0,
    cpu1,
    comparedCost,
    weatherEffect,
    ratesEffect,
    thisYearBill,
    conservationEffect,
  }) => ({
    col1: (date?.split('-')).length > 1 ? format(new Date(date), 'yyyy - MMM') || '—' : date,
    col2: t0 || '—',
    col3: t1 || '—',
    col4: cpu0 || '—',
    col5: cpu1 || '—',
    col6: comparedCost || '—',
    col7: weatherEffect || '—',
    col8: ratesEffect || '—',
    col9: thisYearBill || '—',
    col10: conservationEffect || '—',
  });

  const formatItem = (array) => array.map((
    {
      col1,
      col2,
      col3,
      col4,
      col5,
      col6,
      col7,
      col8,
      col9,
      col10,
    },
  ) => ({
    col1,
    col2: formatToStringDot(col2),
    col3: formatToStringDot(col3),
    col4: formatToStringDot(col4),
    col5: formatToStringDot(col5),
    col6: `$${formatToStringGap(col6)}`,
    col7: `$${formatToStringGap(col7)}`,
    col8: `$${formatToStringGap(col8)}`,
    col9: `$${formatToStringGap(col9)}`,
    col10: `$${formatToStringGap(col10)}`,
  }));

  const availableDateMemo = useMemo(() => availablePeriod, [availablePeriod]);
  const showDateTo = useMemo(() => {
    const date = new Date(selectedStartDateTo);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    return format(new Date(date), 'MM/dd/yyyy');
  }, [selectedStartDateTo]);

  return (
    <MainPageContainer isVisible={isVisible}>
      <MainContentGrid>
        <FacilityInfoBlock />
        {
          !isEmpty(availablePeriod)
          && <SavingsContainer>
            <Typography.H6 cssUnique={EnergyTitle}>
              Energy Savings
            </Typography.H6>
            <DateContainerRow>
              <DateContainer>
                <Typography.TextS cssUnique={FromTitle}>
                  From:
                </Typography.TextS>
                <PickerContainer>
                  <DatePickerHeader>
                    <DatePickerLeftArrow onClick={handleArrowClickFrom(-1)}>
                      <SVG.DatePickerArrowLeft />
                    </DatePickerLeftArrow>
                    <DatePickerDateShow onClick={handleSetVisiblePickerFrom}>
                      {format(new Date(selectedStartDateFrom), 'MMMM yyyy')}
                    </DatePickerDateShow>
                    <DatePickerRightArrow onClick={handleArrowClickFrom(1)}>
                      <SVG.DatePickerArrowRight />
                    </DatePickerRightArrow>
                  </DatePickerHeader>
                  <DatePicker isVisible={isVisiblePickerFrom} ref={datePickerRefFrom}>
                    <DatePickerContainer
                      startDate={selectedStartDateFrom}
                      onDatesSelect={handleStartDateSelectFrom}
                      onYearChange={handleChangeYear}
                      includeDates={availableDateMemo}
                      maxDate={selectedStartDateTo}
                    />
                  </DatePicker>
                </PickerContainer>
                <Typography.TextS cssUnique={ToTitle}>
                  To:
                </Typography.TextS>
                <PickerContainer>
                  <DatePickerHeader>
                    <DatePickerLeftArrow onClick={handleArrowClickTo(-1)}>
                      <SVG.DatePickerArrowLeft />
                    </DatePickerLeftArrow>
                    <DatePickerDateShow onClick={handleSetVisiblePickerTo}>
                      {selectedStartDateTo && format(new Date(selectedStartDateTo), 'MMMM yyyy')}
                    </DatePickerDateShow>
                    <DatePickerRightArrow onClick={handleArrowClickTo(1)}>
                      <SVG.DatePickerArrowRight />
                    </DatePickerRightArrow>
                  </DatePickerHeader>
                  <DatePicker isVisible={isVisiblePickerTo} ref={datePickerRefTo}>
                    <DatePickerContainer
                      minDate={selectedStartDateFrom}
                      startDate={selectedStartDateTo}
                      onDatesSelect={handleStartDateSelectTo}
                      onYearChange={handleChangeYear}
                      includeDates={availableDateMemo}
                    />
                  </DatePicker>
                </PickerContainer>
              </DateContainer>
              <CompareContainer>
                <Typography.TextS cssUnique={CompareTitle}>
                  Compare Against:
                </Typography.TextS>
                <StatusCheckboxItem onClick={handleCheck(BASELINE_YEAR_RADIO)}>
                  <CB
                    name="compare"
                    type="radio"
                    value={radioValue === BASELINE_YEAR_RADIO}
                  />
                  <Typography.TextS
                    cssUnique={StatusCheckboxItemTitle}
                  >
                    Baseline Year
                  </Typography.TextS>
                </StatusCheckboxItem>
                <StatusCheckboxItem onClick={handleCheck(PREVIOUS_YEAR_RADIO)}>
                  <CB
                    name="compare"
                    type="radio"
                    value={radioValue === PREVIOUS_YEAR_RADIO}
                  />
                  <Typography.TextS
                    cssUnique={StatusCheckboxItemTitle}
                  >
                    Previous Year
                  </Typography.TextS>
                </StatusCheckboxItem>
              </CompareContainer>
            </DateContainerRow>

            <PeriodContainer>
              <Typography.H6 cssUnique={PeriodTitle}>
                {/* eslint-disable-next-line max-len */}
                {`AEA saving analysis for the period ${format(new Date(selectedStartDateFrom), 'MM/dd/yyyy')} to ${showDateTo} (comparing to ${radioValue === BASELINE_YEAR_RADIO ? 'baseline' : 'previous'} year)`}
              </Typography.H6>

              <ExportButtonContainer onClick={handleChangeVisabilityExport}>
                <ExportButton isVisible={isVisibleExport}>
                  <SVG.DownloadIconBigger />
                </ExportButton>
                <ExportButtonDropdown isVisible={isVisibleExport} ref={exportDropdown}>
                  <ExportButtonDropdownInner>
                    {
                      DOWNLOAD_DATA_SAVINGS.map(({
                        id, name, value,
                      }) => (
                        <DownloadItem key={id} onClick={handleDownloadClick(value)}>
                          <Typography.TextS>{name}</Typography.TextS>
                        </DownloadItem>
                      ))
                    }
                  </ExportButtonDropdownInner>
                </ExportButtonDropdown>
              </ExportButtonContainer>
            </PeriodContainer>

            <ChartsContainer isBurgerOpen={isVisible}>
              {
                charts.map((i, index) => (
                  <Block3 key={index} isBurgerOpen={isVisible}>
                    <SavingBarLineChartBlock title={i.utilTypeName} data={i.chartData} />
                  </Block3>
                ))
              }
            </ChartsContainer>
            {
              tables.map((i, index) => {
                const columns = setHeaderInTable(i.name !== 'Overall' ? tableColumns : tableColumnsOverall, i.name);

                const rows = formatItem(addTotalItem((
                  i.name === 'Totals'
                    ? i.data.map((i) => ({ ...i, date: i.utilTypeName })).slice(0, -1)
                    : i.data)
                  ?.map((item) => mapItem(item))));

                return (
                  <SavingsTable
                    key={index}
                    tableColumns={columns}
                    tableRows={rows}
                    isVisible={isVisible}
                  />
                );
              })
            }
          </SavingsContainer>
        }
      </MainContentGrid>
      <Footer />
    </MainPageContainer >
  );
};

SavingsPage.propTypes = {
  isVisible: PropTypes.bool,
};

export default SavingsPage;

const MainPageContainer = styled.div`
    width: 100%;
    transition: ${TRANSITIONS.FAST};
    padding: 17px 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 38px;
    min-height: calc(100vh - 110px);
`;

const MainContentGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 94px;
    grid-template-areas: "facilityInfo";
    grid-gap: 16px;
    height: 100%;
`;

const DatePicker = styled.div`
    position: absolute;
    width: 219px;
    height: 203px;
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
    box-shadow: 0px 0px 25px rgba(55, 114, 182, 0.2);
    border-radius: 8px;
    padding: 16px;
    opacity: 0;
    transition: ${TRANSITIONS.FAST};
    pointer-events: none;
    left: -25%;
    top: 40px;

    ${({ isVisible }) => isVisible && css`
        opacity: 1;
        pointer-events: auto;
    `}
`;

const DatePickerHeader = styled.div`
    background: ${COLORS.WHITE};
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
    border-radius: 8px;
    width: 142px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${FONTS.OPEN_SANS};
    color: ${COLORS.LIGHT_BLUE};
    font-size: 13px;
`;

const PickerContainer = styled.div`
    position: relative;
`;

const SavingsContainer = styled.div`

`;

const EnergyTitle = css`
    color: ${COLORS.BLACK_LIGHT};
    font-weight: bold;
    margin-bottom: 16px;
`;

const DateContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DatePickerLeftArrow = styled.div`
    background: ${COLORS.WHITE};
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 4px;
    width: 14px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const DatePickerRightArrow = styled.div`
    background: ${COLORS.WHITE};
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 4px;
    width: 14px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const FromTitle = css`
    color: ${COLORS.BLACK_LIGHT};
    font-weight: bold;
    margin-right: 16px;
`;

const ToTitle = css`
    color: ${COLORS.BLACK_LIGHT};
    font-weight: bold;
    margin-left: 24px;
    margin-right: 16px;
`;

const CompareTitle = css`
    color: ${COLORS.BLACK_LIGHT};
    margin-right: 16px;
    margin-left: 24px;
    font-weight: bold;
`;

const CompareContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DateContainerRow = styled.div`
    display: flex;
    align-items: center;
`;

const StatusCheckboxItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 10px;
  cursor: pointer;
`;

const StatusCheckboxItemTitle = css`
  font-size: 12px;
  line-height: 150.68%;
`;

const PeriodContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
`;

const PeriodTitle = css`
  font-weight: bold;
  color: ${COLORS.BLACK_LIGHT};
  margin-right: 19px;
`;

const Block3 = styled.div`
  box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
  border-radius: 16px;
  background: ${COLORS.WHITE};
  grid-area: totalEnergy;
  width: 32%;

  ${({ isBurgerOpen }) => isBurgerOpen && css`
    ${less.laptop(css`
      width: calc(50% - 16px);
      margin-bottom: 16px;
    `)}
  `}

  ${less.laptopS(css`
    width: calc(50% - 16px);
    margin-bottom: 16px;
  `)}
`;

const ChartsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  ${less.laptopS(css`
    flex-wrap: wrap;
    justify-content: space-around;

    & > div:last-child {
      margin-bottom: 0;
    }
  `)}

  ${({ isBurgerOpen }) => isBurgerOpen && css`
    ${less.laptop(css`
      flex-wrap: wrap;
      justify-content: space-around;

      & > div:last-child {
        margin-bottom: 0;
      }
    `)}
  `}
`;

const DatePickerDateShow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExportButtonContainer = styled.div`
  position: relative;
`;

const ExportButtonDropdown = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: ${TRANSITIONS.FAST};
  left: calc(-43px / 4);

  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    pointer-events: auto;
  `}
`;

const ExportButtonDropdownInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  padding: 6px 0;
  flex-direction: column;
  background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
  box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
  border-radius: 8px;
  margin-top: 4px;
`;

const ExportButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg path {
      transition: ${TRANSITIONS.FAST};
  }
  &:hover {
      svg path {
          fill: ${COLORS.LIGHT_BLUE};
      }
  }
  ${({ isVisible }) => isVisible && css`
    svg path {
      fill: ${COLORS.LIGHT_BLUE};
    }
  `}
`;

const DownloadItem = styled.div`
  display: flex;
  padding: 4px 0;
  cursor: pointer;
  width: 100%;
  justify-content: center;

  & > div {
    font-size: 12px;
    line-height: 150.68%;
  }
`;
