import React, {
  memo,
  useEffect,
  useState,
} from 'react';
import { withRouter } from 'react-router-dom';

import {
  useTable,
  useExpanded,
} from 'react-table';
import PropTypes from 'prop-types';

import {
  TableContainer,
  Table,
  THEAD,
  TBODY,
  TH,
  TR,
  TD,
} from './styles';

import Typography from '../Typography';

const SavingsTable = memo(({
  tableColumns: columns,
  tableRows: data,
  isVisible,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded,
  );

  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    setCurrentPage(rows);
  }, [pageIndex, rows]);

  const ifColouredCell = (value, string) => {
    if (value === 'col7' || value === 'col8' || value === 'col10') {
      return (string.split('-')).length <= 1;
    }
    return null;
  };

  return (
    <TableContainer>
      <Table
        isVisible={isVisible}
        {...getTableProps()}>
        <THEAD
          isVisible={isVisible}>
          {headerGroups.map((headerGroup, i) => (
            <TR
              {...headerGroup.getHeaderGroupProps()}
              key={i}
            >
              {headerGroup.headers.map((column, i) => (
                <TH
                  {...column.getHeaderProps()}
                  key={i}
                  colSpan={0}
                >
                  <Typography.TextS >
                    {column.render('Header')}
                  </Typography.TextS>
                </TH>
              ))}
            </TR>
          ))}
        </THEAD>
        <TBODY
          isVisible={isVisible}
          {...getTableBodyProps()}>
          {currentPage?.map((row) => {
            prepareRow(row);

            return (
              <>
                <TR
                  {...row.getRowProps()}>
                  {row.cells.map((cell, i) => (
                    <TD
                      {...cell.getCellProps()}
                      key={i}
                      coloured={ifColouredCell(cell.column.id, cell.value)}
                    >
                      <Typography.TextS>
                        {cell.render('Cell')}
                      </Typography.TextS>
                    </TD>
                  ))
                  }
                </TR>
              </>
            );
          })}
        </TBODY>
      </Table>
    </TableContainer>
  );
});

SavingsTable.propTypes = {
  tableColumns: PropTypes.array.isRequired,
  tableRows: PropTypes.array.isRequired,
};

SavingsTable.defaultProps = {
  tableColumns: [],
  tableRows: [],
};

export default withRouter(SavingsTable);
