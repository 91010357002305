import {
  SET_IS_LOADING,
  SET_FACILITIES,
  SET_TASKS_MANAGER,
  SET_MEDIA_INFO,
  SET_ORGANIZATION_ID,
  SET_FACILITY_NAME,
} from '../types/types';

const initialState = {
  isLoading: true,
  facilities: [],
  tasksManager: [],
  media: {
    width: 0,
    height: 0,
  },
  organizationId: null,
  facilityName: '',
};

export default function data(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MEDIA_INFO:
      return { ...state, media: payload };
    case SET_IS_LOADING: {
      return { ...state, isLoading: payload };
    }
    case SET_FACILITIES: {
      return { ...state, facilities: payload };
    }
    case SET_TASKS_MANAGER: {
      return { ...state, tasksManager: payload };
    }
    case SET_ORGANIZATION_ID: {
      return { ...state, organizationId: payload };
    }
    case SET_FACILITY_NAME: {
      return { ...state, facilityName: payload };
    }
    default: {
      return state;
    }
  }
}
