import axios from 'axios';
import { format } from 'date-fns';
import ROUTES from '../api/routes';
import getAccessToken from '../helpers/getAccessToken';

export default function getSavingsPageData(compareAgainst, startDate, endDate) {
  return (_, getStore) => {
    const { organizationId } = getStore().data;
    const dateTo = new Date(endDate);
    dateTo.setMonth(dateTo.getMonth() + 1);
    dateTo.setDate(0);

    const params = {
      compareAgainst,
      startDate: format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateTo), 'yyyy-MM-dd'),
    };

    return axios.get(
      `${ROUTES.GET_BUILDINGS}/${organizationId}/savings/`,
      {
        headers: {
          accept: 'application/json',
          Authorization: getAccessToken(),
        },
        params,
      },
    )
      .then(({ data }) => data)
      .catch((err) => err);
  };
}
