import axios from 'axios';
import ROUTES from '../api/routes';
import { TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';

export default function createItemTask(taskData) {
  return (_, getStore) => {
    const { accessToken } = JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME)) || {};
    const { organizationId } = getStore().data;

    const data = JSON.stringify({
      ...taskData,
      buildingId: organizationId,
    });

    return axios.post(
      ROUTES.GET_TASKS,
      data,
      {
        headers: {
          accept: 'application/json',
          Authorization: accessToken,
          'content-type': 'application/json',
        },
      },
    )
      .catch((err) => err);
  };
}
