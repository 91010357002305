import { combineReducers } from 'redux';

import data from './data';
import auth from './auth';

const reducers = combineReducers({
  data,
  auth,
});

export default reducers;
