import axios from 'axios';
import ROUTES from '../api/routes';
import getAccessToken from '../helpers/getAccessToken';

export default function getBuildingConstants() {
  return (_, getStore) => {
    const { organizationId } = getStore().data;

    return axios.get(
      `${ROUTES.GET_BUILDINGS}/${organizationId}/constants`,
      {
        headers: {
          accept: 'application/json',
          Authorization: getAccessToken(),
        },
      },
    )
      .then(({ data }) => data)
      .catch((err) => err);
  };
}
