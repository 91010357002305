import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS, TRANSITIONS } from '../../../styles/Theme';
import Footer from '../../components/Footer/Footer.jsx';
import Typography from '../../components/Typography';
import SVG from '../../components/SVG';
import { Input, TextArea } from '../../components/FormComponents/index.jsx';
import FieldDropdown from '../../components/FieldDropdown/FieldDropdown.jsx';
import SelectFieldForm from '../../components/SelectFieldForm/SelectFieldForm.jsx';
import Editor from '../../components/Editor/index.jsx';
import DatePickerDayContainer from '../../components/DatePickerDayContainer/index.jsx';

import { createTaskValidatorsMap } from '../../../utils/validatorsMap';

import createItemTask from '../../../services/createItemTask';
import { CATEGORY_DATA, STATUS_DATA2 } from '../../../constants/ui';
import getBuildingConstants from '../../../services/getBuildingConstants';
import getTaskById from '../../../services/getTaskById';
import saveItemTask from '../../../services/saveItemTask';
import deleteItemTask from '../../../services/deleteItemTask';
import { less, lessH } from '../../../styles';
import useOutsideClick from '../../../utils/useOutsideClick';
import NotFoundPage from '../NotFoundPage/NotFoundPage.jsx';

const FIELDS = ['title', 'taskDescription', 'annualSavings', 'capitalRequired', 'statusData', 'categoryData', 'associatedEquipmentData'];

const TaskCreatePage = ({
  isVisible,
  history,
  match,
  location,
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);
  const [title, setTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [annualSavings, setAnnualSavings] = useState('');
  const [capitalRequired, setCapitalRequired] = useState('');
  const [statusData, setStatusData] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const [associatedEquipmentData, setAssociatedEquipmentData] = useState('');
  const [editorValue, setEditorValue] = useState('');
  const [isVisibleEditor, setIsVisibleEditor] = useState(false);
  const [createdOnData, setCreatedOnData] = useState('');
  const [lastUpdatedData, setLastUpdatedData] = useState('');
  const [previousData, setPreviousData] = useState({});

  // validation
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isTaskDescriptionValid, setIsTaskDescriptionValid] = useState(true);
  const [isAnnualSavingsValid, setIsAnnualSavingsValid] = useState(true);
  const [isCapitalRequiredValid, setIsCapitalRequiredValid] = useState(true);
  const [isStatusDataValid, setIsStatusDataValid] = useState(true);
  const [isCategoryDataValid, setIsCategoryDataValid] = useState(true);
  const [isAssociatedEquipmentDataValid, setIsAssociatedEquipmentDataValid] = useState(true);
  const [isEditorValueValid, setIsEditorValueValid] = useState(true);
  const [error, setError] = useState('');

  const [isNotFound, setNotFound] = useState(false);
  // const [isLoading, setLoading] = useState(true);

  // PICKER
  const [isVisiblePicker, setVisiblePicker] = useState(false);
  const [completedDate, setCompletedDate] = useState(new Date());

  const { pathname } = location;
  const isEditTaskPage = useMemo(() => pathname.split('/').slice(-2)[0] === 'edit-task', []);

  const [equipmentData, setEquipmentData] = useState([]);

  const mountedStatusData = useRef();
  const mountedCategoryData = useRef();
  const mountedEquipmentData = useRef();
  const datePicker = useRef(null);

  useOutsideClick(datePicker, () => {
    if (isVisiblePicker) handleSetVisiblePicker();
  });

  useEffect(() => {
    if (organizationId) {
      const { params: { id } } = match;

      Promise.allSettled([
        isEditTaskPage && getTaskById(id),
        dispatch(getBuildingConstants()),
      ])
        .then(([{ value: data }, { value: { associatedEquipment } }]) => {
          if (data instanceof Error) {
            setNotFound(!isNotFound);
          }

          const associatedEquipmentData = associatedEquipment?.map((i) => ({
            ...i,
            value: i.name,
            label: i.name,
            isChecked: false,
          }));
          setEquipmentData(associatedEquipmentData);

          const statusObj = STATUS_DATA2.find(({ id }) => id === data.statusId);
          const categoryObj = CATEGORY_DATA.find(({ id }) => id === data.categoryId);

          setTitle(data.title || '');
          setTaskDescription(data.description || '');
          setAnnualSavings(data.saving || '');
          setCapitalRequired(data.capitalRequired || '');
          setStatusData(statusObj);
          setCategoryData(categoryObj);
          setAssociatedEquipmentData(data.equipment || '');
          setCreatedOnData(data.createdOn || '');
          setLastUpdatedData(data.lastUpdated || '');

          setEditorValue(data.notes || '');
          setIsVisibleEditor(!!data.notes);
          data.statusId === 6 && setCompletedDate(new Date(data.completedDate));

          setPreviousData(data);

          // setLoading(false);
        });
    }
  }, [organizationId]);

  useEffect(() => {
    if (!mountedStatusData.current) {
      mountedStatusData.current = true;
    } else {
      !isStatusDataValid && onBlur('statusData')();
    }
  }, [statusData]);

  useEffect(() => {
    if (!mountedCategoryData.current) {
      mountedCategoryData.current = true;
    } else {
      !isCategoryDataValid && onBlur('categoryData')();
    }
  }, [categoryData]);

  useEffect(() => {
    if (!mountedEquipmentData.current) {
      mountedEquipmentData.current = true;
    } else {
      onBlur('associatedEquipmentData')();
    }
  }, [equipmentData]);

  useEffect(() => {
    if (!!equipmentData.length) {
      const arr = associatedEquipmentData?.split(', ');
      const arr2 = equipmentData.map((equipment) => {
        if (arr.find((name) => equipment.name === name)) {
          return ({
            ...equipment,
            isChecked: true,
          });
        }
        return ({
          ...equipment,
          isChecked: false,
        });
      });
      setEquipmentData(arr2);
    }
    return false;
  }, [associatedEquipmentData]);

  const handleTitleChanges = (value) => {
    setTitle(value);
  };

  const handleTaskDescriptionChanges = (value) => {
    setTaskDescription(value);
  };

  const handleAnnualSavingsChanges = (value) => {
    setAnnualSavings(value);
  };

  const handleCapitalRequiredChanges = (value) => {
    setCapitalRequired(value);
  };

  const handleChangeSelectStatus = useCallback((value) => {
    setStatusData(value);
  }, []);

  const handleChangeSelectCategory = (value) => {
    setCategoryData(value);
  };

  const handleChangePage = (data) => {
    setEditorValue(data);
  };

  const handleChangeVisible = () => {
    setIsVisibleEditor(!isVisibleEditor);
  };

  const handleDeleteTask = () => {
    const { params: { id } } = match;
    deleteItemTask(id)
      .then(redirectToTaskManager)
      .catch((err) => err);
  };

  const handleChangeChechbox = useCallback((value) => {
    setEquipmentData(value);
    setAssociatedEquipmentData(value
      .filter(({ isChecked }) => isChecked)
      .reduce((acc, res) => `${acc + res.name}, `, '')
      .slice(0, -2));
  }, []);

  const redirectToTaskManager = () => {
    history.push('/project-center/task-manager');
  };

  const handleSetVisiblePicker = () => {
    setVisiblePicker(!isVisiblePicker);
  };

  const handleChangeDate = useCallback((data) => {
    setCompletedDate(data);
    setVisiblePicker(false);
  }, []);

  const handleResetClick = () => {
    if (isEditTaskPage) {
      const statusObj = STATUS_DATA2.find(({ id }) => id === previousData.statusId);
      const categoryObj = CATEGORY_DATA.find(({ id }) => id === previousData.categoryId);

      setTitle(previousData.title || '');
      setTaskDescription(previousData.description || '');
      setAnnualSavings(previousData.saving || '');
      setCapitalRequired(previousData.capitalRequired || '');
      setStatusData(statusObj || {});
      setCategoryData(categoryObj || {});
      setAssociatedEquipmentData(previousData.equipment || '');
      setEditorValue(previousData.notes || '');
    } else {
      setTitle('');
      setTaskDescription('');
      setAnnualSavings('');
      setCapitalRequired('');
      setStatusData({});
      setCategoryData({});
      setAssociatedEquipmentData('');
      setEditorValue('');
      setIsVisibleEditor(false);
    }
  };

  const validate = () => {
    let isValid = true;

    const field = {
      title,
      taskDescription,
      annualSavings,
      capitalRequired,
      statusData,
      categoryData,
      associatedEquipmentData: equipmentData,
      editorValue,
    };

    const fieldChangeState = {
      title: setIsTitleValid,
      taskDescription: setIsTaskDescriptionValid,
      annualSavings: setIsAnnualSavingsValid,
      capitalRequired: setIsCapitalRequiredValid,
      statusData: setIsStatusDataValid,
      categoryData: setIsCategoryDataValid,
      associatedEquipmentData: setIsAssociatedEquipmentDataValid,
      editorValue: setIsEditorValueValid,
    };

    FIELDS.forEach((name) => {
      let errorTexts;
      const validators = createTaskValidatorsMap[name];

      validators && validators.forEach((validator) => {
        if (errorTexts) {
          return;
        }

        const filedErrorTexts = validator(field[name]);

        if (!filedErrorTexts) {
          isValid = false;
          errorTexts = filedErrorTexts;
        }
      });

      fieldChangeState[name](errorTexts === undefined);
    });
    return !isValid;
  };

  const onBlur = (name) => () => {
    const field = {
      title,
      taskDescription,
      annualSavings,
      capitalRequired,
      statusData,
      categoryData,
      associatedEquipmentData: equipmentData,
      editorValue,
    };

    const fieldChangeState = {
      title: setIsTitleValid,
      taskDescription: setIsTaskDescriptionValid,
      annualSavings: setIsAnnualSavingsValid,
      capitalRequired: setIsCapitalRequiredValid,
      statusData: setIsStatusDataValid,
      categoryData: setIsCategoryDataValid,
      associatedEquipmentData: setIsAssociatedEquipmentDataValid,
      editorValue: setIsEditorValueValid,
    };

    let errorTexts;
    const validators = createTaskValidatorsMap[name];

    validators
      && validators.forEach((validator) => {
        if (errorTexts) {
          return;
        }

        const isEmptyString = typeof field[name] === 'string' && field[name].trim() === '';
        const filedErrorTexts = isEmptyString || validator(field[name]);

        if (filedErrorTexts) {
          errorTexts = filedErrorTexts;
        }
      });

    fieldChangeState[name](!!errorTexts);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isNotValid = validate();
    const { params: { id } } = match;

    if (isNotValid) {
      return false;
    }

    const data = {
      title,
      description: taskDescription,
      savings: annualSavings,
      capital: capitalRequired,
      status: statusData?.id,
      category: categoryData?.id,
      equipment: equipmentData
        .filter(({ isChecked }) => isChecked)
        .reduce((acc, res) => `${acc + res.name}, `, '')
        .slice(0, -2),
      notes: editorValue,
      ...statusData?.id === 6 && { completedAt: format(completedDate, 'yyyy-MM-dd') },
    };
    setError('');

    isEditTaskPage
      ? dispatch(saveItemTask(data, id))
        .then(redirectToTaskManager)
        .catch((err) => err)
      : dispatch(createItemTask(data))
        .then(redirectToTaskManager)
        .catch((err) => err);

    return false;
  };

  // if (isLoading) {
  //   return <div />;
  // }

  if (isNotFound) {
    return <NotFoundPage />;
  }

  return (
    <MainPageContainer isVisible={isVisible}>
      <MainContentGrid>
        <Link to="/project-center/task-manager">
          <Typography.H6 cssUnique={CreateTitle}>
            <SVG.ArrowBack />
            {
              isEditTaskPage
                ? 'Edit Task'
                : 'Create Task'
            }
          </Typography.H6>
        </Link>

        <CreateFormContainer
          autoComplete="off"
          onSubmit={handleSubmit}
          statusData={statusData}
        >
          <StyledInput
            labelText="Title"
            type="text"
            id="input-title"
            value={title}
            onChange={handleTitleChanges}
            isError={!isTitleValid}
            onBlur={onBlur('title')}
            gridArea='title'
          />

          <StyledTextArea
            labelText="Task Description"
            type="text"
            id="input-task-description"
            value={taskDescription}
            onChange={handleTaskDescriptionChanges}
            isError={!isTaskDescriptionValid}
            onBlur={onBlur('taskDescription')}
            gridArea='taskDescription'
            lastEl
          />

          <SelectContainer
            gridArea='statusId'
          >
            <SelectFieldForm
              value={statusData}
              options={STATUS_DATA2}
              onChange={handleChangeSelectStatus}
              isError={!isStatusDataValid}
              labelText="Status"
              id="statusId"
            />
          </SelectContainer>

          <SelectContainer
            gridArea='categoryName'
          >
            <SelectFieldForm
              value={categoryData}
              options={CATEGORY_DATA}
              onChange={handleChangeSelectCategory}
              isError={!isCategoryDataValid}
              labelText="Category"
              id="categoryName"
            />
          </SelectContainer>

          <StyledInput
            labelText="Est. Annual Savings"
            type="text"
            id="input-annual-savings"
            value={annualSavings}
            onChange={handleAnnualSavingsChanges}
            isError={!isAnnualSavingsValid}
            onBlur={onBlur('annualSavings')}
            gridArea='annualSavings'
          />

          <StyledInput
            labelText="Capital Required"
            type="text"
            id="input-capital-required"
            value={capitalRequired}
            onChange={handleCapitalRequiredChanges}
            isError={!isCapitalRequiredValid}
            onBlur={onBlur('capitalRequired')}
            gridArea='capitalRequired'
          />

          <SelectContainer
            gridArea='associatedEquipment'
          >
            <FieldDropdown
              data={equipmentData}
              title={'Associated equipment'}
              width={'100%'}
              cWidth={'100%'}
              onChange={handleChangeChechbox}
              isError={!isAssociatedEquipmentDataValid}
              large
            />
          </SelectContainer>
          <EditorContainer
            isVisible={isVisibleEditor}
          >
            <Editor
              value={editorValue}
              onChange={handleChangePage}
              isError={!isEditorValueValid}
              onChangeVisible={handleChangeVisible}
              isVisible={isVisibleEditor}
            />
          </EditorContainer>

          {
            isEditTaskPage && createdOnData
            && <DateContainer>
              <CreatedOnContainer>
                <Typography.TextS cssUnique={DateTitle}>
                  Created On
                </Typography.TextS>
                <Typography.TextS cssUnique={DateValue}>
                  {createdOnData && format(new Date(createdOnData), 'MM/dd/yyyy, HH:mm')}
                </Typography.TextS>
              </CreatedOnContainer>
              <LastUpdatedContainer>
                <Typography.TextS cssUnique={DateTitle}>
                  Last Updated
                </Typography.TextS>
                <Typography.TextS cssUnique={DateValue}>
                  {lastUpdatedData && format(new Date(lastUpdatedData), 'MM/dd/yyyy, HH:mm')}
                </Typography.TextS>
              </LastUpdatedContainer>
              {
                statusData?.id === 6
                && <CompletedContainer
                  ref={datePicker}
                  isVisible={isVisiblePicker}
                  isEditTaskPage={isEditTaskPage}
                >
                  <Typography.TextS cssUnique={DateTitle}>
                    Completed Date:
                  </Typography.TextS>
                  <CreatedOnHeader onClick={handleSetVisiblePicker}>
                    <Typography.TextS>
                      {completedDate && format(new Date(completedDate), 'MM/dd/yyyy')}
                    </Typography.TextS>
                  </CreatedOnHeader>
                  <DatePicker isVisible={isVisiblePicker}>
                    <DatePickerDayContainer
                      startDate={completedDate}
                      onDateSelect={handleChangeDate}
                    />
                  </DatePicker>
                </CompletedContainer>
              }
            </DateContainer>
          }
          {
            statusData?.id === 6 && !isEditTaskPage
            && <CompletedContainer
              ref={datePicker}
              isVisible={isVisiblePicker}
              isEditTaskPage={isEditTaskPage}
            >
              <Typography.TextS cssUnique={DateTitle}>
                Completed Date:
              </Typography.TextS>
              <CreatedOnHeader onClick={handleSetVisiblePicker}>
                <Typography.TextS>
                  {completedDate && format(new Date(completedDate), 'MM/dd/yyyy')}
                </Typography.TextS>
              </CreatedOnHeader>
              <DatePicker isVisible={isVisiblePicker}>
                <DatePickerDayContainer
                  startDate={completedDate}
                  onDateSelect={handleChangeDate}
                />
              </DatePicker>
            </CompletedContainer>
          }

          {error && <ErrorMessage>
            <Typography.TextS>
              {error}
            </Typography.TextS>
          </ErrorMessage>}
        </CreateFormContainer>
        {
          isEditTaskPage
            ? <ButtonsContainer>
              <CreateButton onClick={handleSubmit}>
                <Typography.TextS cssUnique={CreateButtonTitle}>
                  Save Changes
                </Typography.TextS>
              </CreateButton>

              <DeleteButton onClick={handleDeleteTask}>
                <Typography.TextS cssUnique={CreateButtonTitle}>
                  Delete Task
                </Typography.TextS>
              </DeleteButton>

              <ResetButton onClick={handleResetClick}>
                <Typography.TextS cssUnique={ResetTitle}>
                  Reset
                </Typography.TextS>
              </ResetButton>
            </ButtonsContainer>
            : <ButtonsContainer>
              <CreateButton onClick={handleSubmit}>
                <Typography.TextS cssUnique={CreateButtonTitle}>
                  Create Task
                </Typography.TextS>
              </CreateButton>
              <ResetButton onClick={handleResetClick}>
                <Typography.TextS cssUnique={ResetTitle}>
                  Reset
                </Typography.TextS>
              </ResetButton>
            </ButtonsContainer>
        }
      </MainContentGrid>
      <Footer />
    </MainPageContainer>
  );
};

TaskCreatePage.propTypes = {
  isVisible: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(TaskCreatePage);

const MainPageContainer = styled.div`
  width: 100%;
  transition: ${TRANSITIONS.FAST};
  padding: 17px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 38px;
  height: 100%;
`;

const MainContentGrid = styled.div`
  background: ${COLORS.WHITE};
  box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
  border-radius: 24px;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: calc(100vh - 170px);

  & > a {
    text-decoration: none;
    z-index: 1;
    position: absolute;
  }
/* 
  ${less.laptop(css`
    height: auto;
  `)} */

  ${lessH.tablet(css`
    min-height: auto;
  `)}
`;

const CreateTitle = css`
  color: ${COLORS.BLACK_LIGHT};
  font-weight: bold;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const CreateFormContainer = styled.form`
  position: relative;
  display: grid;
  grid-template-areas: "title title taskDescription taskDescription"
                       "statusId categoryName taskDescription taskDescription"
                       "annualSavings capitalRequired associatedEquipment associatedEquipment"
                       "notes notes notes notes"
                       ;
  grid-column-gap: 16px;
  grid-row-gap: 36px;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  height: auto;
  margin-top: 67px;
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  margin: 0;
  height: auto;

  textarea {
    transition: ${TRANSITIONS.FAST};
    color: ${COLORS.BLACK_LIGHT};
    resize: none; 

    &::-webkit-scrollbar {
      width: 8px;
      background: ${COLORS.LIGHTER_BLUE};
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${COLORS.LIGHT_BLUE};
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${COLORS.BLUE};
      cursor: pointer;
    }
  }

  ${({ gridArea }) => gridArea && css`
    grid-area: ${gridArea};
  `}

  ${({ isError }) => isError && css`
    color: ${COLORS.RED_LIGHT};

    label {
      color: ${COLORS.RED_LIGHT};
    }
  `}
`;

const StyledInput = styled(Input)`
  width: 100%;
  margin: 0;
  height: auto;

  input {
    transition: ${TRANSITIONS.FAST};
    color: ${COLORS.BLACK_LIGHT}
  }

  ${({ lastEl }) => lastEl && css`
    margin-bottom: 0px;
  `}

  ${({ gridArea }) => gridArea && css`
    grid-area: ${gridArea};
  `}

  ${({ isError }) => isError && css`
    color: ${COLORS.RED_LIGHT};

    svg path {
      stroke: ${COLORS.RED_LIGHT} !important;
    }

    label {
      color: ${COLORS.RED_LIGHT};
    }
  `}
`;

const ErrorMessage = styled.div`
  color: ${COLORS.RED_LIGHT};
  position: absolute;
  bottom: 47px;
  font-weight: 600;
  left: 0;

  div {
    font-weight: bold;
  }
`;

const SelectContainer = styled.div`
  z-index: 2;
  ${({ gridArea }) => gridArea && css`
    grid-area: ${gridArea};
  `}
`;

const EditorContainer = styled.div`
  grid-area: notes;
  width: max-content;
  width: auto;

  ${({ isVisible }) => isVisible && css`
    width: auto;
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 78px;
`;

const CreateButton = styled.div`
  display: flex;
  background: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  font-weight: 600;
  align-items: center;
  padding: 7px 16px;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 8px;

  svg {
    margin-right: 6px;
  }

  a {
    color: ${COLORS.WHITE};
    text-decoration: none;
    display: flex;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  background: ${COLORS.RED_LIGHT};
  color: ${COLORS.WHITE};
  font-weight: bold;
  align-items: center;
  padding: 7px 16px;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 8px;

  svg {
    margin-right: 6px;
  }

  a {
    color: ${COLORS.WHITE};
    text-decoration: none;
    display: flex;
  }
`;

const CreateButtonTitle = css`
  font-weight: bold;
`;

const ResetTitle = css`
  font-weight: bold;
`;

const ResetButton = styled.div`
  display: flex;
  color: ${COLORS.LIGHT_BLUE};
  padding: 7px 21px;
  border: 1px solid ${COLORS.LIGHT_BLUE};
  cursor: pointer;
  border-radius: 8px;

  svg {
      margin-right: 6px;
  }
`;

const DateContainer = styled.div`
  display: flex;
`;
const CreatedOnContainer = styled.div`
  display: flex;
`;
const LastUpdatedContainer = styled.div`
  display: flex;
`;

const DateTitle = css`
  color: ${COLORS.LIGHT_BLUE};
  white-space: nowrap;
  margin-right: 16px;
`;
const DateValue = css`
  color: ${COLORS.BLACK_LIGHT};
  white-space: nowrap;
  margin-right: 24px;
`;

const CompletedContainer = styled.div`
  /* grid-area: completedData; */
  position: relative;
  z-index: 1;
  display: flex;
  width: max-content;

  ${({ isVisible }) => isVisible && css`
    z-index: 2;
  `}

  ${({ isEditTaskPage }) => isEditTaskPage && css`
    /* margin-left: -16px; */
  `}
`;

const DatePicker = styled.div`
    position: absolute;
    width: 219px;
    height: 212px;
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
    box-shadow: 0px 0px 25px rgba(55, 114, 182, 0.2);
    border-radius: 8px;
    padding: 16px;
    opacity: 0;
    transition: ${TRANSITIONS.FAST};
    pointer-events: none;
    left: 112px;
    top: -20px;

    ${({ isVisible }) => isVisible && css`
      opacity: 1;
      pointer-events: auto;
    `}
`;

const CreatedOnHeader = styled.div`
  display: flex;
  flex-direction: space-between;
  align-items: center;
  padding: 6px 10px;
  background: white;
  box-shadow: 0px 0px 25px rgb(55 114 182 / 20%);
  border-radius: 8px;
  color: #1D4E86;
  margin-top: -5px;
  cursor: pointer;

  & > div {
    font-weight: bold;
  }
`;

// const CompletedTitle = css`
//   font-weight: bold;
//   margin-right: 24px;
// `;
