import React, { useEffect, useState, memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import format from 'date-fns/format';
import {
  DatepickerWrapper,
  MonthTitle,
  GlobalStyle,
} from './styles';

const DatePickerContainer = memo((props) => {
  const [startDate, setStartDate] = useState(null || props.startDate);

  useEffect(() => {
    setStartDate(props.startDate);
  }, [props.startDate]);

  const onChange = (date) => {
    setStartDate(date);
    props.onDatesSelect(date);
  };
  const onMonthChange = () => {
    props.onYearChange();
  };

  return (
    <DatepickerWrapper>
      <GlobalStyle />
      <MonthTitle>
        {startDate ? format(new Date(startDate), 'MMM') : ''}
      </MonthTitle>
      <DatePicker
        onYearChange={onMonthChange}
        onChange={onChange}
        selected={startDate ? new Date(startDate) : null}
        {...(props.minDate && { minDate: new Date(props.minDate) })}
        {...(props.maxDate && { maxDate: new Date(props.maxDate) })}
        showMonthYearPicker
        includeDates={props.includeDates}
        inline
      />
    </DatepickerWrapper>
  );
});

export default DatePickerContainer;
