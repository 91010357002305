import { Route, Switch, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../components/Header/Header.jsx';
import MainPage from './MainPage/MainPage.jsx';
import LoginPage from './LoginPage/LoginPage.jsx';
import RegisterPage from './RegisterPage/RegisterPage.jsx';
import RecoveryPage from './RecoveryPage/RecoveryPage.jsx';
import UtilityDetailPage from './UtilityDetailPage/UtilityDetailPage.jsx';
import BurgerMenu from '../components/BurgerMenu/BurgerMenu.jsx';
import TaskManagerPage from './TaskManagerPage/TaskManagerPage.jsx';
import TaskCreatePage from './TaskCreatePage/TaskCreatePage.jsx';
import SubHeader from '../components/SubHeader/SubHeader.jsx';
import NotFoundPage from './NotFoundPage/NotFoundPage.jsx';

import account from '../../services/account';
import SavingsPage from './SavingsPage/SavingsPage.jsx';
import useComponentWillMount from '../../utils/useComponentWillMount';
import { ADMIN_ROLE } from '../../constants/constants.js';
import { TRANSITIONS } from '../../styles/Theme.js';
import { less } from '../../styles/responsive.js';

function App() {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const isAuthorized = useSelector(({ auth }) => auth.authorized);
  const isLoading = useSelector(({ data }) => data.isLoading);

  useComponentWillMount(() => {
    dispatch(account());
  });

  const withoutAuthRedirect = (Component) => {
    const RedirectComponent = ({ history, location }) => {
      const [isVisible, setIsVisible] = useState(false);

      const handleChangeVisabolity = () => {
        setIsVisible(!isVisible);
      };

      if (isLoading) {
        return <div />;
      }
      if (isAuthorized === false) {
        return <Redirect history={history} to='/login' />;
      }
      if (location.pathname === '/') {
        return <Redirect history={history} to='/project-center/project-dashboard' />;
      }

      return <>
        <Header />
        <ContainerLaylout>
          <BurgerMenu
            history={history}
            isVisible={isVisible}
            onChangeVisability={handleChangeVisabolity}
          />
          <ComponentContainer isVisible={isVisible}>
            <SubHeader isVisible={isVisible} />
            <Component isVisible={isVisible} />
          </ComponentContainer>
        </ContainerLaylout>
      </>;
    };

    return withRouter(RedirectComponent);
  };

  const withAuthRedirect = (Component) => {
    const RedirectComponent = ({ history }) => {
      const userId = useSelector(({ auth }) => auth.user && auth.user.id);

      useEffect(() => {
        dispatch(account());
      }, []);

      if (isLoading) {
        return <div />;
      }

      if (isAuthorized && !userId) {
        return <Redirect history={history} to='/project-center/project-dashboard' />;
      }

      if (isAuthorized && userId) {
        <Redirect history={history} to='/project-center/project-dashboard' />;
      }

      return <Component />;
    };

    return withRouter(RedirectComponent);
  };

  return (
    <main>
      <Switch>
        <Route exact path="/" component={withoutAuthRedirect(MainPage)} />
        <Route exact path="/login" component={withAuthRedirect(LoginPage)} />
        {
          isAuthorized && user?.role === ADMIN_ROLE
          && <Route exact path="/sign-up" component={RegisterPage} />
        }
        <Route exact path="/recovery" component={withAuthRedirect(RecoveryPage)} />
        <Route exact path="/facility/utility-detail" component={withoutAuthRedirect(UtilityDetailPage)} />
        <Route exact path="/project-center/project-dashboard" component={withoutAuthRedirect(MainPage)} />
        <Route exact path="/project-center/task-manager" component={withoutAuthRedirect(TaskManagerPage)} />
        <Route exact path="/project-center/task-manager/create-task" component={withoutAuthRedirect(TaskCreatePage)} />
        <Route exact path="/project-center/task-manager/edit-task/:id" component={withoutAuthRedirect(TaskCreatePage)} />
        <Route exact path="/project-center/saving" component={withoutAuthRedirect(SavingsPage)} />
        <Route component={NotFoundPage} />
      </Switch>
    </main>
  );
}

export default withRouter(App);

const Redirect = ({ to, history }) => {
  useEffect(() => {
    history.push(to);
  }, [to]);

  return null;
};

const ContainerLaylout = styled.main`
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  transition: .5s;
`;

const ComponentContainer = styled.div`
  margin-left: auto;
  width: 100%;
  transition: ${TRANSITIONS.FAST};

  ${({ isVisible }) => isVisible && css`
    width: calc(100% - 208px);
  `}

  ${less.tabletL(css`
    ${({ isVisible }) => isVisible && css`
      width: 100%;
    `}
  `)}
`;
