import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Typography from '../Typography';

import {
  SubHeaderContainer,
  SubHeaderInnerContainer,
  HeaderItem,
  SubHeaderItemContainer,
  ModalSubHeader,
  SubHeaderItem,
  ModalSubHeaderContainer,
  SubHeaderItemModal,
  SubHeaderItemModalContainer,
  SubHeaderItemTitle,
} from './styles';

import { ADMIN_ROLE } from '../../../constants/constants';

const SubHeader = memo(({ isVisible, history }) => {
  const user = useSelector(({ auth }) => auth.user);
  const isLoading = useSelector(({ data }) => data.isLoading);
  const organizationId = useSelector(({ data }) => data.organizationId);

  const handleRedirect = (src) => () => {
    src && history.push(`${src}`);
  };

  const DATA = [
    {
      id: 0,
      title: 'Your Facility',
      width: 162,
      list: [
        {
          id: 0,
          title: 'Overview',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Building%20Summary`,
        },
        {
          id: 1,
          title: 'Utility details',
          src: '/facility/utility-detail',
        },
        {
          id: 2,
          title: 'Energy usage analysis',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Energy%20Usage%20Analysis`,
        },
        {
          id: 3,
          title: 'EPA Ratings',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#EPA%20Ratings/History`,
        },
      ],
    },
    {
      id: 1,
      title: 'Project Center',
      list: [
        {
          id: 0,
          title: 'Project Dashboard',
          src: '/project-center/project-dashboard',
        },
        {
          id: 1,
          title: 'Task Manager',
          src: '/project-center/task-manager',
        },
        {
          id: 2,
          title: 'Savings',
          src: '/project-center/saving',
        },
        {
          id: 3,
          title: 'Saving History',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Savings%20History`,
        },
      ],
    },
    {
      id: 2,
      title: 'Building Automation',
      width: 171,
      list: [
        {
          id: 0,
          title: 'Automation dashboard',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Building%20Automation`,
        },
        {
          id: 1,
          title: 'Equipment',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Equipment`,
        },
        {
          id: 2,
          title: 'Equipment analysis',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#BAS%20Analysis`,
        },
        {
          id: 3,
          title: 'Analysis tool',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#v6%20Application`,
        },
        {
          id: 4,
          title: 'Trending tool',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#BAS%20v41%20Graphs`,
        },
        {
          id: 5,
          title: 'Management',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#BAS%20v6%20Management`,
        },
      ],
    },
    {
      id: 3,
      title: 'Sustainability',
      list: [
        {
          id: 0,
          title: 'Dashboard',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Sustainability%20Dashboard`,
        },
        {
          id: 1,
          title: 'Carbon footprint',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Carbon%20Footprint`,
        },
      ],
    },
    {
      id: 4,
      title: 'Admin',
      width: 159,
      list: [
        {
          id: 0,
          title: 'Manage user content',
          list: [
            {
              id: 0,
              title: 'User roles',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Manage%20User%20Roles`,
            },
            {
              id: 1,
              title: 'Contacts',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Contacts%20Summary`,
            },
            {
              id: 2,
              title: 'Capture summary',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Capture%20Summary`,
            },
          ],
        },
        {
          id: 1,
          title: 'Data management',
          width: 173,
          list: [
            {
              id: 0,
              title: 'Building setup',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Manage%20Building%20Detail`,
            },
            {
              id: 1,
              title: 'Manage kBtu Sf Targets',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#kBtu%20Sf%20Targets`,
            },
            {
              id: 2,
              title: 'EPA Actions',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Building%20EPA%20Actions`,
            },
            {
              id: 3,
              title: 'Utility Accounts',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Utility%20Accounts`,
            },
          ],
        },
        {
          id: 2,
          title: 'Baselines',
          width: 164,
          list: [
            {
              id: 0,
              title: 'Baseline Tool',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Baseline%20Tool`,
            },
            {
              id: 1,
              title: 'Baseline Management',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Baseline%20Management`,
            },
            {
              id: 2,
              title: 'Baseline App',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Baseline%20App`,
            },
          ],
        },
        {
          id: 3,
          title: 'Advanced',
          list: [
            {
              id: 0,
              title: 'Building Areas',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Manage%20Building%20Areas`,
            },
            {
              id: 1,
              title: 'Manage Ratings',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Manage%20Ratings`,
            },
            {
              id: 2,
              title: 'Utility Data',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Manage%20Utility%20Data`,
            },
            {
              id: 3,
              title: 'Building Attributes',
              link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Building%20Attributes`,
            },
          ],
        },
        {
          id: 4,
          title: 'Utility report',
          link: `http://bob.americanenergyassets.com/building/${organizationId}.html#Building%20Utility%20Report`,
        },
      ],
    },
  ];

  if (isLoading) return <div />;

  return (
    <SubHeaderContainer isVisible={isVisible}>
      <SubHeaderInnerContainer>
        {
          (user?.role === ADMIN_ROLE ? DATA : DATA.slice(0, -1))
            .map((item, i) => (
              <SubHeaderItemContainer key={i}>
                <Typography.TextS cssUnique={HeaderItem}>
                  {item.title}
                </Typography.TextS>
                {
                  item.list
                  && <ModalSubHeader>
                    <ModalSubHeaderContainer width={item.width}>
                      {
                        item.list.map((subitem, i) => (
                          <React.Fragment key={i}>
                            <SubHeaderItem onClick={handleRedirect(subitem.src)}>
                              {
                                subitem.src
                                  ? <SubHeaderItemTitle>
                                    {subitem.title}
                                  </SubHeaderItemTitle>
                                  : <SubHeaderItemTitle
                                    target="_blank"
                                    href={subitem.link}>
                                    {subitem.title}
                                  </SubHeaderItemTitle>
                              }
                              {
                                subitem.list
                                && <SubHeaderItemModalContainer>
                                  <SubHeaderItemModal width={subitem.width}>
                                    {
                                      subitem.list.map((subsubitem, i) => (
                                        <SubHeaderItem key={i} subsubitem>
                                          <SubHeaderItemTitle
                                            target="_blank"
                                            {...subsubitem.link
                                            && { href: subsubitem.link }}>
                                            {subsubitem.title}
                                          </SubHeaderItemTitle>
                                        </SubHeaderItem>
                                      ))
                                    }
                                  </SubHeaderItemModal>
                                </SubHeaderItemModalContainer>
                              }
                            </SubHeaderItem>
                          </React.Fragment>
                        ))
                      }
                    </ModalSubHeaderContainer>
                  </ModalSubHeader>
                }
              </SubHeaderItemContainer>
            ))
        }
      </SubHeaderInnerContainer>
    </SubHeaderContainer>
  );
});

SubHeader.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(SubHeader);
