import styled, { css } from 'styled-components/macro';
import { less } from '../../../styles';
import { COLORS, TRANSITIONS } from '../../../styles/Theme';

export const SubHeaderContainer = styled.div`
    padding-bottom: 14px;
    padding-top: 14px;
    width: 100%;
    transition: ${TRANSITIONS.FAST};
    padding-left: 67px;
    position: fixed;
    background: linear-gradient(107.41deg,#F8F8FB 49.07%,#FFFCF3 109.41%);
    z-index: 10;
    border-bottom: 1px solid #E4EDF4;
    right: 0;
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);

    ${({ isVisible }) => isVisible && css`
        margin-left: 0px;
        width: calc(100% - 208px);
        padding-left: 24px;
        z-index: 12;
    `}

    ${less.tabletL(css`
        ${({ isVisible }) => isVisible && css`
            width: 100%;
            z-index: 10;
            padding-left: 67px;
        `};
  `)}
`;

export const SubHeaderInnerContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const HeaderItem = css`
    transition: ${TRANSITIONS.FAST};
    color: ${COLORS.GREY_LIGHTER2};
    font-weight: bold;

    ${({ isActive }) => isActive && css`
        color: ${COLORS.LIGHT_BLUE3};
    `}
`;

export const SubHeaderItemContainer = styled.div`
    position: relative;
    transition: ${TRANSITIONS.QUICK};
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 32px;
    }

    &:hover {
        & > div:first-child {
            color: #2a609e;
            font-weight: bold;
        }

        & > div:last-child > div {
            opacity: 1;
            pointer-events: auto;
        }
    }
`;

export const ModalSubHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: -20px;
    z-index: 5;
    width: 100%;
    display: flex;
    justify-content: center;
    height: -webkit-fill-available;
`;

export const ModalSubHeaderContainer = styled.div`
    position: relative;
    min-width: ${({ width }) => (width ? `${width}px` : '150px')};
    background: linear-gradient(51.64deg,#3772B6 -15.06%,#1D4E86 139.87%);
    border-radius: 8px;
    padding: 6px 0;
    margin-top: 28px;
    height: max-content;
    opacity: 0;
    pointer-events: none;
    transition: ${TRANSITIONS.QUICK};
      
    &:after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 0;
        top: -24px;
        width: 0;
        left: calc(50% - 15px);
        border: 15px solid transparent;
        border-bottom-color: #2a609e;
    }

    &:before{
        content: "";
        position: absolute;
        bottom: 0;
        height: 0;
        top: -23px;
        width: -webkit-fill-available;
        left: 0;
        border: 15px solid transparent;
    }
`;

export const SubHeaderItem = styled.div`
    color: ${COLORS.WHITE};
    cursor: pointer;
    transition: ${TRANSITIONS.FAST};
    position: relative;
    text-overflow: ellipsis;
    padding: 4px 15px;
    height: 25px;
    /* font-size: 13px;
    line-height: 18px; */
    
    &:hover {
        background: ${COLORS.BLUE};
        font-weight: bold;

        & div:last-child {
            pointer-events: none;
        }

        & div:last-child div:last-child {
            opacity: 1;
            pointer-events: auto;
        }
    }

    a {
        color: ${COLORS.WHITE};
        text-decoration: none;
        width: 100%;
        font-size: 13px;
        line-height: 149.18%;
        white-space: nowrap;
    }

    ${({ subsubitem }) => subsubitem && css`
        font-weight: normal;

        &:hover {
            font-weight: bold;
        }
    `}
`;

export const SubHeaderItemTitleA = styled.a`
  color: ${COLORS.WHITE};
    cursor: pointer;
    transition: ${TRANSITIONS.FAST};
    position: relative;
    text-overflow: ellipsis;
    padding: 4px 15px;
    height: 25px;
    font-size: 13px;
    line-height: 18px;
    
    &:hover {
        background: ${COLORS.BLUE};
        font-weight: bold;

        & div:last-child {
            pointer-events: none;
        }

        & div:last-child div:last-child {
            opacity: 1;
            pointer-events: auto;
        }
    }

    a {
        color: ${COLORS.WHITE};
        text-decoration: none;
        width: 100%;
        font-size: 13px;
        line-height: 149.18%;
        white-space: nowrap;
    }

    ${({ subsubitem }) => subsubitem && css`
        font-weight: normal;

        &:hover {
            font-weight: bold;
        }
    `}
`;

export const SubHeaderItemTitle = styled.a`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 18px;
`;

export const SubHeaderItemModalContainer = styled.div`
    position: absolute;
    left: 159px;
    top: -6px;
    pointer-events: none;
`;

export const SubHeaderItemModal = styled.div`
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : '150px')};
    background: linear-gradient(51.64deg,#3772B6 -15.06%,#1D4E86 139.87%);
    border-radius: 8px;
    padding: 6px 0;
    height: max-content;
    opacity: 0;
    pointer-events: none;
    transition: ${TRANSITIONS.QUICK};
`;

export const SubHeaderItemInnerContainer = styled.div``;
