import {
  SET_AUTHENTICATION,
  SET_USER,
} from '../types/types';

const initialState = {
  authorized: null,
  user: null,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTHENTICATION: {
      return { ...state, authorized: payload };
    }
    case SET_USER: {
      return { ...state, user: payload };
    }
    default: {
      return state;
    }
  }
}
