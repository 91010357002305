import styled, { css } from 'styled-components/macro';
import { less } from '../../../styles';

import { COLORS, TRANSITIONS } from '../../../styles/Theme';

export const TableContainer = styled.section`
  margin: 24px 0 16px 0;
  position: relative;
  width: inherit;

  &:last-child {
    margin: 24px 0 8px 0; 
  }
`;

export const TableHeader = styled.div`
display: flex;
padding: 0 12px;
align-items: center;
height: 52px;
`;

export const HeaderTitle = css`
margin-left: 42px;
font-weight: bold;
margin-right: 16px;
`;

export const Table = styled.table`
/* width: 100%; */
border-collapse: collapse;
overflow: auto;
position: relative;
display: flex;
flex-direction: column;
border-radius: 8px 8px 0px 0px;
box-shadow: 0px 0px 25px rgb(55 114 182 / 20%);
width: 100%;
overflow: auto;

${({ isVisible }) => isVisible && css`
  ${less.laptopS(css`
    width: calc(100vw - 48px - 208px);
  `)}
`}

${less.tabletL(css`
  width: calc(100vw - 48px);
`)}

&::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: ${COLORS.LIGHTER_BLUE};
  border-radius: 8px;
}

&::-webkit-scrollbar-thumb {
  background: ${COLORS.LIGHT_BLUE};
  border-radius: 8px;
}

&::-webkit-scrollbar-thumb:hover {
  background: ${COLORS.BLUE};
  cursor: pointer;
}
`;

export const THEAD = styled.thead`
background: linear-gradient(51.64deg, #3772B6 -15.06%, #1D4E86 139.87%);
position: sticky;
top: 0;
z-index: 1;
width: 100%;

${({ isVisible }) => isVisible && css`
  ${less.laptopS(css`
    min-width: 1200px;
  `)}
`}

${less.tabletL(css`
  min-width: 1200px;
`)};

& > tr {
  height: 32px;
  padding: 0 12px;

  &:hover {
    background: linear-gradient(51.64deg, #3772B6 -15.06%, #1D4E86 139.87%);
  }
}
`;

export const TBODY = styled.tbody`
position: relative;
width: 100%;
border-bottom-left-radius: 16px;
border-bottom-right-radius: 16px;
z-index: 0;
background: ${COLORS.WHITE};

${({ isVisible }) => isVisible && css`
  ${less.laptopS(css`
    min-width: 1200px;
  `)}
`}

${less.tabletL(css`
  min-width: 1200px;
`)};
`;

export const TH = styled.th`
  color: ${COLORS.WHITE};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0 12px;
  text-align: left;
  cursor: pointer;

  & > div {
    font-weight: bold;
  }
`;

export const TR = styled.tr`
  display: flex;
  justify-content: space-between;
  transition: ${TRANSITIONS.FAST};
  position: relative;
  padding: 0 12px;
  align-items: center;

  &:last-child {
    border-top: 1px solid ${COLORS.LIGHTER_BLUE};
    height: 36px;
  }
`;

export const TD = styled.td`
  padding: 8px 12px 4px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: left;
  left: 0;

  ${({ coloured }) => coloured === true && css`
    & div {
      color: ${COLORS.GREEN_DARK};
    }
  `}

  ${({ coloured }) => coloured === false && css`
    & div {
      color: ${COLORS.RED_LIGHT};
    }
  `}
`;
