import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { COLORS } from '../../../styles/Theme.js';
import BarVerticalLine from '../charts/BarVerticalLine/BarVerticalLine.jsx';
import Typography from '../Typography.js';

const CHART_DATA = [
  {
    selectId: 0,
    labels: ['Baseline Cost (AdJ)', 'Weather Effect', 'Rates Effect', 'Conservation Effect', 'This Year Bill'],
    datasets: [
      {
        type: 'line',
        label: '$',
        borderColor: COLORS.BLACK_LIGHT,
        fill: false,
        stepped: 'after',
        borderWidth: 1,
        min: 7,
        tension: 7,
      },
      {
        label: '$',
        borderColor: COLORS.BLACK_LIGHT,
        borderWidth: 1,
        datalabels: {
          display: true,
          align: ['start', 'end', 'end', 'end', 'start'],
          anchor: ['end', 'end', 'end', 'end', 'end'],
        },
      },
    ],
  },
];

const SavingBarLineChartBlock = memo(({ title, data }) => {
  const [chartData, setChartData] = useState(CHART_DATA[0]);
  useEffect(() => {
    if (data) {
      const edgeMeanPer = ((data[0] + data[4]) / 2) / 30;

      setChartData(() => ({
        ...chartData,
        id: Date.now(),
        edgeMeanPer,
        datasets: chartData.datasets.map((i) => (i.type === 'line'
          && !i.isAxes
          ? ({
            ...i,
            data: [
              data[0],
              data[1] > 0
                ? edgeMeanPer + data[0] + data[1]
                : -edgeMeanPer + data[0] + data[1],
              data[2] > 0 && data[1] > 0
                ? edgeMeanPer + edgeMeanPer + data[0] + data[1] + data[2]
                : data[2] > 0 && data[1] < 0
                  ? edgeMeanPer + -edgeMeanPer + data[0] + data[1] + data[2]
                  : data[2] < 0 && data[1] > 0
                    ? -edgeMeanPer + edgeMeanPer + data[0] + data[1] + data[2]
                    : -edgeMeanPer - edgeMeanPer + data[0] + data[1] + data[2],
              data[3] > 0 && data[2] > 0 && data[1] > 0 // 1 >  >  >
                ? edgeMeanPer + edgeMeanPer + edgeMeanPer + data[0] + data[1] + data[2] + data[3]
                : data[3] > 0 && data[2] > 0 && data[1] < 0 // 2 >  >  <
                  ? edgeMeanPer + edgeMeanPer - edgeMeanPer + data[0] + data[1] + data[2] + data[3]
                  : data[3] > 0 && data[2] < 0 && data[1] < 0 // 3 >  <  <
                    ? edgeMeanPer - edgeMeanPer - edgeMeanPer
                    + data[0] + data[1] + data[2] + data[3]
                    : data[3] > 0 && data[2] < 0 && data[1] > 0 // 4 >  <  >
                      ? edgeMeanPer - edgeMeanPer + edgeMeanPer
                      + data[0] + data[1] + data[2] + data[3]
                      : data[3] < 0 && data[2] > 0 && data[1] > 0 // 5 <  >  >
                        ? -edgeMeanPer + edgeMeanPer + edgeMeanPer
                        + data[0] + data[1] + data[2] + data[3]
                        : data[3] < 0 && data[2] > 0 && data[1] > 0 // 6 <  <  >
                          ? -edgeMeanPer - edgeMeanPer + edgeMeanPer
                          + data[0] + data[1] + data[2] + data[3]
                          : data[3] < 0 && data[2] < 0 && data[1] < 0 // 7 < < <
                            ? -edgeMeanPer - edgeMeanPer - edgeMeanPer
                            + data[0] + data[1] + data[2] + data[3]
                            : -edgeMeanPer + edgeMeanPer - edgeMeanPer
                            + data[0] + data[1] + data[2] + data[3], // 8 < > <
              (data[3] > 0 && data[2] > 0 && data[1] > 0 // 1 >  >  >
                ? edgeMeanPer + edgeMeanPer + edgeMeanPer + data[0] + data[1] + data[2] + data[3]
                : data[3] > 0 && data[2] > 0 && data[1] < 0 // 2 >  >  <
                  ? edgeMeanPer + edgeMeanPer - edgeMeanPer + data[0] + data[1] + data[2] + data[3]
                  : data[3] > 0 && data[2] < 0 && data[1] < 0 // 3 >  <  <
                    ? edgeMeanPer - edgeMeanPer - edgeMeanPer
                    + data[0] + data[1] + data[2] + data[3]
                    : data[3] > 0 && data[2] < 0 && data[1] > 0 // 4 >  <  >
                      ? edgeMeanPer - edgeMeanPer + edgeMeanPer
                      + data[0] + data[1] + data[2] + data[3]
                      : data[3] < 0 && data[2] > 0 && data[1] > 0 // 5 <  >  >
                        ? -edgeMeanPer + edgeMeanPer + edgeMeanPer
                        + data[0] + data[1] + data[2] + data[3]
                        : data[3] < 0 && data[2] > 0 && data[1] > 0 // 6 <  <  >
                          ? -edgeMeanPer - edgeMeanPer + edgeMeanPer
                          + data[0] + data[1] + data[2] + data[3]
                          : data[3] < 0 && data[2] < 0 && data[1] < 0 // 7 < < <
                            ? -edgeMeanPer - edgeMeanPer - edgeMeanPer
                            + data[0] + data[1] + data[2] + data[3]
                            : -edgeMeanPer + edgeMeanPer - edgeMeanPer
                            + data[0] + data[1] + data[2] + data[3]),
              // data[4] - edgeMeanPer,
            ],
          })
          : i.isAxes
            ? i
            : ({
              ...i,
              data: [
                data[0],
                data[1] > 0
                  ? data[1] + edgeMeanPer
                  : data[1] - edgeMeanPer,
                data[2] > 0
                  ? data[2] + edgeMeanPer
                  : data[2] - edgeMeanPer,
                data[3] > 0
                  ? data[3] + edgeMeanPer
                  : data[3] - edgeMeanPer,
                (data[3] > 0 && data[2] > 0 && data[1] > 0 // 1 >  >  >
                  ? edgeMeanPer + edgeMeanPer + edgeMeanPer
                  + data[0] + data[1] + data[2] + data[3]
                  : data[3] > 0 && data[2] > 0 && data[1] < 0 // 2 >  >  <
                    ? edgeMeanPer + edgeMeanPer - edgeMeanPer
                    + data[0] + data[1] + data[2] + data[3]
                    : data[3] > 0 && data[2] < 0 && data[1] < 0 // 3 >  <  <
                      ? edgeMeanPer - edgeMeanPer - edgeMeanPer
                      + data[0] + data[1] + data[2] + data[3]
                      : data[3] > 0 && data[2] < 0 && data[1] > 0 // 4 >  <  >
                        ? edgeMeanPer - edgeMeanPer + edgeMeanPer
                        + data[0] + data[1] + data[2] + data[3]
                        : data[3] < 0 && data[2] > 0 && data[1] > 0 // 5 <  >  >
                          ? -edgeMeanPer + edgeMeanPer + edgeMeanPer
                          + data[0] + data[1] + data[2] + data[3]
                          : data[3] < 0 && data[2] > 0 && data[1] > 0 // 6 <  <  >
                            ? -edgeMeanPer - edgeMeanPer + edgeMeanPer
                            + data[0] + data[1] + data[2] + data[3]
                            : data[3] < 0 && data[2] < 0 && data[1] < 0 // 7 < < <
                              ? -edgeMeanPer - edgeMeanPer - edgeMeanPer
                              + data[0] + data[1] + data[2] + data[3]
                              : -edgeMeanPer + edgeMeanPer - edgeMeanPer // 8
                              + data[0] + data[1] + data[2] + data[3]),
                // data[4] - edgeMeanPer,
              ],
              isTwoMore: data[3] < 0 && data[2] < 0 && data[1] < 0,
              isLastBarMore: (data[3] > 0 && data[2] > 0 && data[1] > 0 // 1 >  >  >
                ? edgeMeanPer + edgeMeanPer + edgeMeanPer + data[0] + data[1] + data[2] + data[3]
                : data[3] > 0 && data[2] > 0 && data[1] < 0 // 2 >  >  <
                  ? edgeMeanPer + edgeMeanPer - edgeMeanPer + data[0] + data[1] + data[2] + data[3]
                  : data[3] > 0 && data[2] < 0 && data[1] < 0 // 3 >  <  <
                    ? edgeMeanPer - edgeMeanPer - edgeMeanPer
                    + data[0] + data[1] + data[2] + data[3]
                    : data[3] > 0 && data[2] < 0 && data[1] > 0 // 4 >  <  >
                      ? edgeMeanPer - edgeMeanPer + edgeMeanPer
                      + data[0] + data[1] + data[2] + data[3]
                      : data[3] < 0 && data[2] > 0 && data[1] > 0 // 5 <  >  >
                        ? -edgeMeanPer + edgeMeanPer + edgeMeanPer
                        + data[0] + data[1] + data[2] + data[3]
                        : data[3] < 0 && data[2] > 0 && data[1] > 0 // 6 <  <  >
                          ? -edgeMeanPer - edgeMeanPer + edgeMeanPer
                          + data[0] + data[1] + data[2] + data[3]
                          : data[3] < 0 && data[2] < 0 && data[1] < 0 // 7 < < <
                            ? -edgeMeanPer - edgeMeanPer - edgeMeanPer
                            + data[0] + data[1] + data[2] + data[3]
                            : -edgeMeanPer + edgeMeanPer - edgeMeanPer
                            + data[0] + data[1] + data[2] + data[3]) > data[4],
            })
        )),
      }));
    }
  }, [data]);

  return (
    <>
      <BlockHeader>
        <Typography.TextS>
          {title}
        </Typography.TextS>
      </BlockHeader>
      <BlockContainer>
        {data && <BarVerticalLine data={chartData} />}
      </BlockContainer>
    </>
  );
});

export default SavingBarLineChartBlock;

const BlockHeader = styled.div`
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
    border-radius: 8px;
    padding: 6px;
    display: flex;
    justify-content: center;
    background:${COLORS.WHITE};

    div {
        color: ${COLORS.BLUE};
        font-weight: bold;
    }
`;

const BlockContainer = styled.div`
    padding: 12px 24px;

    canvas {
      max-height: 200px !important;
    }
`;
