import axios from 'axios';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import ROUTES from '../api/routes';
import { FACILITY_NAME, TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';

export default function getSavingsPageReport(compareAgainst, startDate, endDate, value) {
  return (_, getStore) => {
    const { accessToken } = JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME)) || {};
    const facilityName = localStorage.getItem(FACILITY_NAME);
    const { organizationId } = getStore().data;

    const dateTo = new Date(endDate);
    dateTo.setMonth(dateTo.getMonth() + 1);
    dateTo.setDate(0);

    const params = {
      compareAgainst,
      startDate: format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateTo), 'yyyy-MM-dd'),
    };

    return axios.get(
      `${ROUTES.GET_BUILDINGS}/${organizationId}/savings/report/${value}/`,
      {
        headers: {
          accept: 'application/json',
          Authorization: accessToken,
        },
        responseType: 'blob',
        params,
      },
    )
      .then(({ data }) => {
        const date = format(new Date(), 'MM/dd/yyyy');
        fileDownload(data, `${facilityName}-Savings-${date}.${value === 'xlsx' ? 'xlsx' : 'pptx'}`);
      })
      .catch((err) => err);
  };
}
