import { createGlobalStyle } from 'styled-components/macro';
import { fonts } from './fonts';
import { reset } from './reset';
import { FONTS } from './Theme';

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${fonts}
    * {
        box-sizing: border-box;
        font-family: ${FONTS.OPEN_SANS} !important;
    }
`;

export default GlobalStyle;
